@font-face {
    font-family: 'Anton';
    src: url('../fonts/anton-regular-webfont.woff2') format('woff2'),
         url('../fonts/anton-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,
body {
  margin: 0;
  display: block;
  background: #ddd6cf;
  /* overflow-x: hidden; */
  font-family: 'Open sans', Helvetica, sans-serif;
}

h1, h2, h3, h4, .header {
  font-family: 'Anton', sans-serif;
  font-weight: 'normal';
  color: #3F5C76;
}
p, ul, ol {
  color: #383838;
}
a {
  color: #3F5C76;
}


.header {
  font-size: 18px;
  transition: color 1s;
}


.social-icons li {
  position: relative;
  margin-top: -15px;
  display: inline-block;
  margin-right: 25px;
}
.social-icons li:last-child {
  margin-right: 0;
}
.social-icons a .fa {
  color: #8BB060 !important;
}


.col {
  float: left;
  display: block;
}
.col ul {
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  line-height: 23px;
}
.col ul li {
  line-height: 1.3em;
}
.col ul span {
  padding-right: 5px;
  color: #646361;
}
.col li.header {
  margin-bottom: 5px;
  font-size: 22px;
  text-transform: uppercase;
}
.col a {
  text-decoration: none;
}


div[class^='styles_handle'], div[class*=' styles_handle']{
    width: 5px;
}


ul.quote-list li {
  overflow: visible;
}
ul.quote-list li:before {
  display: block;
  position: absolute;
  background: url('../images/quote.svg') bottom right no-repeat;
  width: 19px;
  height: 19px;
  content: '';
  left: 5px;
  top: -10px;
  zoom: 130%;
}
ul.quote-list li:after {
  display: block;
  position: absolute;
  background: url('../images/quote.svg') bottom right no-repeat;
  width: 19px;
  height: 19px;
  content: '';
  right: 5px;
  bottom: -8px;
  zoom: 130%;
  transform: rotate(180deg);
}


.hscroll {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.flex {
  display: flex;
  flex-wrap: nowrap;
}


.slider-frame {
  height: auto !important;
  min-height: 250px !important;
  max-height: 640px !important;
}
.slider-control-bottomcenter {
  position: relative;
}


/* CSS Table */
div.blueTable {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.divTable.blueTable .dtc, .divTable.blueTable .divTableHead {
  padding: 5px 2px;
}
.dtc:empty::before {
  color: #4a4a4a;
  content: '--';
}
.divTable.blueTable .divTableHeading .divTableHead {
  font-weight: bold;
}
.blueTable .tableFootStyle {
  font-weight: bold;
}
.divTable{ display: table; }
.dtr { display: table-row; }
.divTableHeading { display: table-header-group;}
.dtc, .divTableHead { display: table-cell;}
.divTableHeading { display: table-header-group;}
.divTableFoot { display: table-footer-group;}
.divTableBody { display: table-row-group; color: #A1A1A1;}



.sticky {
  position: -webkit-sticky;
  position: sticky;
}
